import elementResizeDetectorMaker from 'element-resize-detector';

const erd = elementResizeDetectorMaker({ strategy: 'scroll' });

export default {
  name: 'ElementQuery',
  data() {
    return {
      width: null,
      height: null,
      eqSmall: false,
    };
  },
  mounted() {
    erd.listenTo(this.$el, (el) => {
      this.width = el.offsetWidth;
      this.height = el.offsetHeight;
      if (this.width) {
        this.eqSmall = this.width < 600;
      }
    });
  },
  watch: {
    eqSmall: {
      handler(newValue) {
        this.$emit('on-small-change', newValue);
      },
      immediate: true,
    },
  },
  render() {
    return this.$scopedSlots.default({
      width: this.width,
      height: this.height,
      eqSmall: this.eqSmall,
    });
  },
};
