<!--
<template>
  <div id="app" style="">
    <FcnOferta2 />
  </div>
</template>
-->
<script>
import FcnOferta2023 from './components/FcnOferta2023.vue';

export default FcnOferta2023;

/*
export default {
  name: 'app',
  components: {
    FcnOferta2,
  },
};
*/
</script>

<style lang="scss">

</style>
